#sidebar {
    &.active {
        .sidebar-wrapper {
            left: 0;
        }
    }
    &:not(.active) {
        .sidebar-wrapper {
            left: -300px;
        }
        & ~ #main {
            margin-left: 0;
        }
    }
}

.sidebar-wrapper {
    height: 100vh;
    position: fixed;
    top: 0;
    z-index: 10;
    overflow-y: auto;
    background-color: #fff;
    bottom: 0;
    transition: left .5s ease-out;
    box-shadow: 0px 0px 10px -5px #888888;
    max-width: 250px;
    .sidebar-header {
        padding: 2rem 2rem 1rem;
        font-size: 2rem;
        font-weight: 500;

        img {
            height: 1.2rem;
        }

        .logo span {
            padding-left: 1rem;
        }
    }
    .sidebar-toggler.x {
        position: absolute;
        right: 0;
        top: .5rem;
        display:none;
    }

    .menu {
        padding-left: 0;
        margin-top: 1.3125rem;
        padding-right: 1.75rem;
        font-weight: 600;
        .sidebar-title {
            padding: 0 1rem;
            margin: 1.5rem 0 1rem;
            font-size: 1rem;
            list-style: none;
            font-weight: 600;
            color: #25396f;
        }

        .sidebar-link {
            display: block;
            padding: 0.85rem 1.3125rem;
            font-size: 1rem;
            display: flex;
            align-items: center;
            border-radius: 0;
            transition: all .5s;
            text-decoration: none;
            color:#000;
            font-weight: 500;
            svg,i {
                color:#000; /* if this is the color of a possible icon, we have changed it to black as in design */
            }
            &:hover {
                background-color: #f4f7fc;
            }

        }
        .sidebar-item {
            list-style: none;
            margin-top: .5rem;
            position: relative;
            &.has-sub {
                .sidebar-link:after {
                    content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9" style="fill:none;stroke:gray;stroke-width:1"></polyline></svg>');
                    position: absolute;
                    color: #ccc;
                    right: 15px;
                    top: 12px;
                    display:block;
                }
            }
            &.active {
                .sidebar-link {
                    background-color: #f4f7fc;
                    border-left: 4px solid #0058ff;
                    span {
                        color: #0c93e8;
                    }
                    svg,i {
                        fill: white;
                        color:white;
                    }

                    &.has-sub:after {
                        content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9" style="fill:none;stroke:#fff;stroke-width:1"></polyline></svg>');
                    }
                }
            }
        }
        .submenu {
            list-style: none;
            display: none;
            // max-height: 0;
            transition: max-height 2s cubic-bezier(0, 0.55, 0.45, 1);
            overflow: hidden;
            padding-left: 4rem;
            &.active {
                max-height: 999px;
                display: block;
            }
            .submenu-item {
                margin-top: 0.5rem;
                &.active {
                    background-color: $light;
                    position:relative;
                    & > a {
                        color: #0c93e8;
                    }
                }
                a {
                    padding: 0.563rem 1.25rem;
                    display: block;
                    color: #000;

                    font-size: .85rem;
                    font-weight: 600;
                    letter-spacing: .5px;
                    transition: all .3s;
                    &:hover {
                        margin-left: .3rem;
                        background-color: $light;
                    }
                    &.active {
                        background-color: $light;
                        color: #0c93e8;
                    }
                }
            }

        }
    }
}
@media screen and (max-width: 1199px) {
    .sidebar-wrapper {
        position:absolute;
        left: -300px;
        .sidebar-toggler.x  {
            display:block;
        }
    }
}
@media screen and (min-width: 1200px) {

}
