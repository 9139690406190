/*
* Mazer, Technology Innovations Bootstrap HTML5 Landing Page
* 
* File: app.scss
*
* If you want to edit the components, just go to _variables.scss
*/

/**

====== TABLE OF CONTENTS =======
1. Bootstrap & Variables Inside
2. Bootstrap Components Override 
    2.1 Alert
    2.2 Avatar
    2.3 Badge
    2.4 Buttons
    2.5 Breadcrumb
    2.6 Carousel
    2.7 Card
    2.8 Divider
    2.9 Dropdowns
    2.10 Forms
    2.11 Modal
    2.12 Sidebar
    2.13 Navs
    2.14 Navbar
    2.15 Pagination
    2.16 Table
    2.17 Progress
4. Pages
    4.0 Layout
    4.1 Auth
    4.2 Error
5. Utilities
6. Custom Styles

*/


// 1. Bootstrap
@import "./variables";

// 2. Bootstrap Components Override


// - 2.3. Badge
@import "./components/badge";

// - 2.4. Buttons
@import "./components/buttons";

// - 2.7. card
@import "./components/card";

// - 2.8. divider
@import "./components/divider";

// - 2.9. dropdowns
@import "./components/dropdowns";

// - 2.10. forms
@import "./components/forms";

// - 2.11. modal
@import "./components/modal";

// - 2.12. sidebar
@import "./components/sidebar";

// - 2.13. navs
@import "./components/navs";

// - 2.14. navbar
@import "./components/navbar";

// - 2.15. pagination
@import "./components/pagination";

// - 2.16. table
@import "./components/table";

// - 2.17. progress
@import "./components/progress";

// - 2.18. icons
@import "./components/icons";

// 4.0 Layout

@import "./layouts/main.scss";
// 5.0 Utilities
@import "./utilities";

// 6.0 Custom Styles

/* You can add global styles to this file, and also import other style files */

html {
    height: 100%;
}

body {
    min-height: 100vh;
    font-family: 'Poppins';
}

.app-root {
    min-height: 100vh;
    display: grid;
}

/* Style for burger icon */
.bi-justify::before { content: "\f42a"; }


/* bootstrap button theme overrides */

.btn-primary {
    background-color: $blue;
    color: $white !important;

    &:hover {
        background-color: $darkblue;
      }
}

.btn-secondary {
    background-color: $white;
    color: $gray !important;
    border-color: $defaultBorder !important;

    &:hover {
        background-color: $defaultBorder;
      }
}

.btn-green {
    background-color: $green;
    color: $white !important;

    &:hover {
        background-color: $teal;
      }
}

/* class for adding 'light' type background color  */
.light-bg {
    background-color: #f4f7fc;
}

/* Custom toaster theming and ngx toastr theme overrides */

.ngx-toastr {
    width: 500px !important;
}

.map-toast {
    top: 0;
    margin-top: 200px;
    margin-left: 600px;
}

.dual-container {
    width: 100%;
    display: flex;
    justify-content:space-between;
}

.left {
    float: left;
}

.right {
    float: right;
}

.fw-600 {
    font-weight: 600;
}

.w-10 {
    width: 10% !important;
}

.w-20 {
    width: 20% !important;
}

.w-30 {
    width: 30% !important;
}

.w-40 {
    width: 40% !important;
}

.w-50 {
    width: 50% !important;
}

.w-60 {
    width: 60% !important;
}

.w-70 {
    width: 70% !important;
}

.w-80 {
    width: 80% !important;
}

.w-90 {
    width: 90% !important;
}

.onethird {
    width: 33%;
}
