@media only screen and (max-width: 1199px) {
    #main {
        margin-left: 0;
    }
}

@media only screen and (min-width: 1400px) {
    #main {
        margin-left: 250px;
    }
}

// @media only screen and (min-width: 1200px) and (max-width: 1399px) {
//     .main-with-sidebar {
//         margin-left: 25% !important;
//     }
// }

#main {
    &.layout-navbar {
        padding: 0;
    }
    #main-content {
        padding: 2rem;
    }
}

.page-heading {
    margin:  0 0 0.938rem;
    h3 {
        font-weight: 500;
        font-size: 1.5rem;
    }
}
.page-title-headings {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: .5rem;
    h3 {
        margin-bottom: 0;
        margin-right: 1rem;
    }
    .breadcrumb {
        margin-bottom: 0;
    }
}
a {
    text-decoration: none;
}

h4 {
    font-size: 1.25rem;
    padding: 0.5rem 0;
}