@import '../assets/scss/variables';

select {
    background-color: white;
}

.page-container {
    padding-left: 1.313rem;
    padding-right: 1.313rem;
    padding-top: calc(#{$headerHeight} + 1.938rem);
    padding-bottom: 1rem;
    height: auto;
}

.page-title {
    padding-left: 0.5rem;
}

.btn-icon {
    margin-right: 0.2rem;
}

.blue-text {
    color: $blue !important;
}

.yellowish-text {
    color: #FF9F0A !important;
}

.gray-text {
    color: $gray !important;
}

.red-text {
    color: $red !important;
}

.light-text {
    color: $light !important;
}

.green-text {
    color: $green !important;
}

.text-right {
    text-align: right;
}

.rounded-card {
    padding: 1rem;
    margin: 1rem;
    border-radius: 0.5rem;
    background-color: white;
}

.rounded-card-sm {
    border-radius: 0.5rem;
    background-color: white;
}

.sharp-shadow {
    box-shadow: 0.1rem 0.15rem 0.15rem rgba(0, 0, 0, 0.15)
}

.dropdown-item {
    cursor: pointer;
}

.dp24 {
    width: 24px;
}

@media only screen and (max-width: 1399px) {
    .sidebar-wrapper {
        width: 25%;
    }
}

@media only screen and (min-width: 1400px) {
    .sidebar-wrapper {
        width: 16.66%;
    }
}

// Margin
.mr-0 { margin-right: 0 !important;}
.mr-1 { margin-right: 0.3rem; }
.mr-3 { margin-right: 0.9rem; }
.mr-5 { margin-right: 1.5rem; }
.mr-10 { margin-right: 3rem; }

.ml-0 { margin-left: 0 !important;}
.ml-1 { margin-left: 0.3rem; }
.ml-5 { margin-left: 1.5rem; }
.ml-10 { margin-left: 3rem; }

.mt-1 { margin-top: 0.3rem; }
.mt-3 { margin-top: 0.9rem; }
.mt-10 { margin-top: 3rem; }

// Padding
.pt-3 { padding-top: 0.9rem; }

// Font weight

.fw-500 {
    font-weight: 500;
}

.cursor-pointer {
    cursor: pointer;
}

.lh-body {
    line-height: var(--bs-body-line-height);
}

.toast-container {
    top: 100px;
    z-index: 999;
}
